import React, { useRef } from 'react'
import { getClass } from '../../../../helpers/stringHelper'
import TextareaStyled from './Textarea.styled'
import pictoWarning from '../../../../../assets/images/attention.svg'

export default function Textarea({
  className, name, title, value, maxLength, error, onChange, ref,
}) {
  const textareaRef = useRef(null)

  const handleChange = (event) => {
    const cursorPosition = event.target.selectionStart
    const newValue = event.target.value

    onChange(newValue)

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.setSelectionRange(cursorPosition, cursorPosition)
      }
    }, 0)
  }

  return (
    <TextareaStyled className={getClass(className, error && 'error')} pictoWarning={pictoWarning}>
      {title && <div className="title">{title}</div>}
      <div className="area">
        <textarea
          ref={ref || textareaRef}
          style={{ boxShadow: 'initial' }}
          aria-label={`${name} textarea`}
          value={value || ''}
          onChange={handleChange}
          maxLength={maxLength || null}
        />
        {error && (
          <div className="error">
            <span className="error-icon" />
            <span className="error-text">{error}</span>
          </div>
        )}
      </div>
    </TextareaStyled>
  )
}
