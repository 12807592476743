import styled from 'styled-components'

export const TimeInputLogsAfterSynchronizationsTableStyled = styled.div`
h2 {
  margin-bottom: 2rem;
}

div.octo.hard {
  &.filled.month-picker {
    width: 30rem;
    margin-bottom: 3rem;

    div.control {
      div.button-group-month {
        button.month-button-navigator {
          font-size: 1.75rem;
        }
      }
    }
  }

  &.data-table {
    margin-bottom: 5rem;

    &::after {
      content: ' ';
      display: block;
      height: 5rem;
    }
  }
}

`
