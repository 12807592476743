import React, { useContext, createContext, useState, useEffect } from 'react'
import { useFetch } from '../../fetchOverviewApi'

const TimeInputLogsAfterSynchronizationsContext = createContext()

export const TimeInputLogsAfterSynchronizationsContextProvider = ({ children, timeInputLogsAfterSynchronisations }) => {
  const [formattedDataForTable, setFormattedDataForTable] = useState(null)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const transformDataForTable = (data) => Object.fromEntries(data.map((timeInputLogAfterSynchronization, index) => [index, timeInputLogAfterSynchronization]))

  const fetchGetTimeInputLogsAfterSynchronizationsFrom = async (date) => {
    setIsLoading(true)

    const response = await useFetch(
      'GET',
      `time_input_logs_after_synchronizations?date=${date}`,
    )

    if (response.errors) {
      setFormattedDataForTable({})
      setErrors([...errors, ...response.errors])
    } else {
      setFormattedDataForTable(transformDataForTable(response.time_input_logs_after_synchronizations))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setFormattedDataForTable(transformDataForTable(timeInputLogsAfterSynchronisations))
  }, [timeInputLogsAfterSynchronisations])

  return (
    <TimeInputLogsAfterSynchronizationsContext.Provider
      value={{
        formattedDataForTable,
        isLoading,
        fetchGetTimeInputLogsAfterSynchronizationsFrom,
      }}
    >
      {children}
    </TimeInputLogsAfterSynchronizationsContext.Provider>
  )
}

export const useTimeInputLogsAfterSynchronizationsContext = () => useContext(TimeInputLogsAfterSynchronizationsContext)
