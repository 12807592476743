import React from 'react'
import { ThemeProvider } from 'styled-components/macro'

import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { TimeInputLogsAfterSynchronizationsContextProvider } from '../../context/TimeInputLogsAfterSynchronizationsContext/TimeInputLogsAfterSynchronizationsContext'
import TimeInputLogsAfterSynchronizationsTable from '../../components/TimeInputLogsAfterSynchronizationsTable/TimeInputLogsAfterSynchronizationsTable'

export const TimeInputLogsAfterSynchronizationsScreen = ({ timeInputLogsAfterSynchronisations }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <TimeInputLogsAfterSynchronizationsContextProvider timeInputLogsAfterSynchronisations={timeInputLogsAfterSynchronisations}>
      <TimeInputLogsAfterSynchronizationsTable />
    </TimeInputLogsAfterSynchronizationsContextProvider>
  </ThemeProvider>
)
