import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, InputDate } from '@produits-internes-oss/design-system-components'
import { TimeInputLogsAfterSynchronizationsTableStyled } from './TimeInputLogsAfterSynchronizationsTable.style'
import { useTimeInputLogsAfterSynchronizationsContext } from '../../context/TimeInputLogsAfterSynchronizationsContext/TimeInputLogsAfterSynchronizationsContext'
import { getDateStringFromTimestamp, getMonthFirstDay, monthList } from '../../helpers/datePickerHelper'
import Loader from '../UI/Loader/Loader'

export default function TimeInputLogsAfterSynchronizationsTable() {
  const { t } = useTranslation()
  const {
    formattedDataForTable,
    isLoading,
    fetchGetTimeInputLogsAfterSynchronizationsFrom,
  } = useTimeInputLogsAfterSynchronizationsContext()

  const [dateForDatePicker, setDateForDatePicker] = useState(null)

  if (formattedDataForTable === null) {
    return <Loader />
  }

  const columns = [
    {
      name: 'nickname',
      title: t('timeInputLogsAfterSynchronization.table.ownerNickname'),
      onRender: ({ item }) => item['person_nickname'],
      onSort: ((a, b) => a['person_nickname'].localeCompare(b['person_nickname'])),
    },
    {
      name: 'sap_id',
      title: t('timeInputLogsAfterSynchronization.table.ownerRegistrationNumber'),
      onRender: ({ item }) => item['person_registration_number'],
      onSort: ((a, b) => parseInt(a['person_registration_number'], 10) - parseInt(b['person_registration_number'], 10)),
    },
    {
      name: 'action',
      title: t('timeInputLogsAfterSynchronization.table.action'),
      onRender: ({ item }) => t(`timeInputLogsAfterSynchronization.${item['action']}`),
      onSort: ((a, b) => t(`timeInputLogsAfterSynchronization.${a['action']}`).localeCompare(t(`timeInputLogsAfterSynchronization.${b['action']}`))),
    },
    {
      name: 'activity_type',
      title: t('timeInputLogsAfterSynchronization.table.activityType'),
      onRender: ({ item }) => t(`timeInputLogsAfterSynchronization.${item['activity_type']}`),
      onSort: ((a, b) => t(`timeInputLogsAfterSynchronization.${a['activity_type']}`).localeCompare(t(`timeInputLogsAfterSynchronization.${b['activity_type']}`))),
    },
    {
      name: 'activity_name',
      title: t('timeInputLogsAfterSynchronization.table.activityName'),
      onRender: ({ item }) => item['activity_name'],
      onSort: ((a, b) => a['activity_name'].localeCompare(b['activity_name'])),
    },
    {
      name: 'input_date',
      title: t('timeInputLogsAfterSynchronization.table.timeInputDate'),
      onRender: ({ item }) => getDateStringFromTimestamp(new Date(item['time_input_date'])),
      onSort: ((a, b) => new Date(a['time_input_date']).getTime() - new Date(b['time_input_date']).getTime()),
    },
    {
      name: 'author_nickname',
      title: t('timeInputLogsAfterSynchronization.table.authorNickname'),
      onRender: ({ item }) => item['author_nickname'],
      onSort: ((a, b) => a['author_nickname'].localeCompare(b['author_nickname'])),
    },
    {
      name: 'modification_date',
      title: t('timeInputLogsAfterSynchronization.table.modifiationDate'),
      onRender: ({ item }) => getDateStringFromTimestamp(new Date(item['modification_date'])),
      onSort: ((a, b) => new Date(a['modification_date']).getTime() - new Date(b['modification_date']).getTime()),
    },
    {
      name: 'old_state',
      title: t('timeInputLogsAfterSynchronization.table.oldState'),
      onRender: ({ item }) => item['old_state'],
      onSort: ((a, b) => parseInt(a['old_state'], 10) - parseInt(b['old_state'], 10)),
    },
    {
      name: 'new_state',
      title: t('timeInputLogsAfterSynchronization.table.newState'),
      onRender: ({ item }) => item['new_state'],
      onSort: ((a, b) => parseInt(a['new_state'], 10) - parseInt(b['new_state'], 10)),
    },
  ]

  const getDateForDatePicker = () => {
    if (dateForDatePicker !== null) {
      return dateForDatePicker
    }
    if (Object.keys(formattedDataForTable).length > 0) {
      return getMonthFirstDay(new Date(formattedDataForTable[0].time_input_date))
    }
    return getMonthFirstDay(new Date())
  }

  return (
    <TimeInputLogsAfterSynchronizationsTableStyled>
      <h2>{t('timeInputLogsAfterSynchronization.title')}</h2>

      <InputDate
        skin="light"
        name={t('project.activities.staffingDate')}
        valueInitial={getDateForDatePicker()}
        onChange={(value) => {
          setDateForDatePicker(value)
          fetchGetTimeInputLogsAfterSynchronizationsFrom(value)
        }}
        className="month-picker"
        precision="month"
        localization={{
          months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
          previous: t('project.coordination.expenses.month.previous'),
          next: t('project.coordination.expenses.month.next'),
        }}
      />

      { isLoading ? (
        <Loader />
      ) : (
        <Table
          className="data-table"
          columns={columns}
          data={formattedDataForTable}
          sortInitial={{ column: 'modification_date', order: 'desc' }}
        />
      )}

    </TimeInputLogsAfterSynchronizationsTableStyled>
  )
}
