import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, InputDate, InputSearch, Tag, Tooltip } from '@produits-internes-oss/design-system-components'
import ReactMarkdown from 'react-markdown'
import { useFetch } from '../../fetchOverviewApi'
import { HolidaysStyled } from './Holidays.styled'
import { monthList } from '../../helpers/datePickerHelper'
import { mod } from '../../helpers/numberHelper'
import { getClass } from '../../helpers/stringHelper'
import { HolidaysCounter } from '../HolidaysCounter/HolidaysCounter'
import octolidaysSpring from '../../../assets/images/octolidays-spring.svg'
import octolidaysSummer from '../../../assets/images/octolidays-summer.svg'
import octolidaysAutumn from '../../../assets/images/octolidays-autumn.svg'
import octolidaysWinter from '../../../assets/images/octolidays-winter.svg'

const DATE_TODAY = new Date()

const ILLUSTRATIONS = [
  octolidaysSpring,
  octolidaysSummer,
  octolidaysAutumn,
  octolidaysWinter,
]

const SEARCH_LENGTH_MIN = 3

const ACTIVITY_PART_TIME = 2140316822

const URL_CONFLUENCE = 'https://octo.atlassian.net/wiki/spaces/RH/pages/11632658/Temps+de+travail+cong+s+et+absences'
const URL_SUPPORT_PAIE = 'octo.paie@accenture.com'
const URL_SUPPORT_ADMINRH = 'octo.adminrh@accenture.com'

export const Holidays = ({ absences, activities, person, holidays, isAdmin, isCurrentPerson }) => {
  const { t } = useTranslation()

  if (!person && !holidays) return <center>{t('holidays.invalid')}</center>
  if (person && !holidays) return <center>{t('holidays.unauthorized')}</center>

  const months = useMemo(() => [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))], [monthList])

  const date = useMemo(() => new Date(holidays.date.year, holidays.date.month - 1, 1), [holidays])
  const year = useMemo(() => date.getFullYear(), [date])
  const dateEntry = useMemo(() => new Date(person['entry_date']), [person])
  const dateLeaving = useMemo(() => new Date(person['leaving_date']), [person])
  const datePaidLeaveReliquatPostpone = useMemo(() => (person['paid_leave_reliquats_postpone'] ? new Date(person['paid_leave_reliquats_postpone']) : null), [person])
  const datePaidLeaveReliquatStart = useMemo(() => new Date(year, 5, 1), [date])
  const datePaidLeaveReliquatEnd = useMemo(() => datePaidLeaveReliquatPostpone || new Date(year, 7, 31), [date, datePaidLeaveReliquatPostpone])
  const dateRTTReliquatPostpone = useMemo(() => (person['rtt_reliquats_postpone'] ? new Date(person['rtt_reliquats_postpone']) : null), [person])
  const dateRTTReliquatStart = useMemo(() => new Date(year, 0, 1), [date])
  const dateRTTReliquatEnd = useMemo(() => dateRTTReliquatPostpone || null, [date, dateRTTReliquatPostpone])

  const partTimeID = useMemo(() => Object.keys(activities).find((key) => key === ACTIVITY_PART_TIME.toString()), [activities])
  const partTimeAbsences = useMemo(() => (partTimeID && absences[year] ? absences[year][partTimeID] || [] : []), [absences, date, partTimeID])
  const partTimeShouldHideRTT = useMemo(() => partTimeAbsences.length > 0 && !person['is_forfait_jours'], [])

  const totalRTT = useMemo(() => person['work_schedule']['rtt_accumulation'] * 12, [person])

  const illustration = useMemo(() => ILLUSTRATIONS[parseInt(mod(date.getMonth() - 2, 12) / 3, 10)], [date])

  const setWindowDate = (value) => {
    const locationDate = value.getTime() > new Date().getTime() ? value : new Date()

    window.location.search = value
      ? `?date=${locationDate.getFullYear()}-${locationDate.getMonth() + 1}-${new Date(locationDate.getFullYear(), (locationDate.getMonth() + 1), 0).getDate()}`
      : ''
  }

  const counters = useMemo(() => ({
    'rtt_reliquat': {
      advices: [],
      alert: partTimeShouldHideRTT && t('holidays.rtt_reliquat.alert'),
      limit: dateRTTReliquatEnd,
      consumed: parseFloat(holidays['rtt_reliquat'].consumed),
      total: parseFloat(holidays['rtt_reliquat'].total),
      visible: dateRTTReliquatPostpone ? date <= dateRTTReliquatPostpone : date >= dateRTTReliquatStart && date <= dateRTTReliquatEnd,
    },
    'rtt': {
      advices: totalRTT === 0 ? [t(`holidays.rtt.advices.0`)] : [
        person['is_forfait_jours']
          ? t(`holidays.rtt.advices.2`, { year, total: totalRTT.toFixed(2) })
          : t(`holidays.rtt.advices.1`, { year, total: totalRTT.toFixed(2), example: (person['work_schedule']['rtt_accumulation'] * 2).toFixed(2) }),
        t(`holidays.rtt.advices.3`),
        t(`holidays.rtt.advices.4`),
      ],
      alert: partTimeShouldHideRTT && t('holidays.rtt.alert'),
      limit: new Date(year, 11, 31),
      consumed: parseFloat(holidays['rtt'].consumed) + parseFloat(holidays['rcr'].consumed),
      total: parseFloat(holidays['rtt'].total) + parseFloat(holidays['rcr'].total),
      visible: true,
    },
    'conge_reliquat': {
      advices: [
        t(`holidays.conge_reliquat.advices.0`),
      ],
      limit: datePaidLeaveReliquatEnd,
      consumed: parseFloat(holidays['conge_reliquat'].consumed) + parseFloat(holidays['conge_reliquat_anciennete'].consumed),
      total: parseFloat(holidays['conge_reliquat'].total) + parseFloat(holidays['conge_reliquat_anciennete'].total),
      visible: datePaidLeaveReliquatPostpone ? date <= datePaidLeaveReliquatPostpone : date >= datePaidLeaveReliquatStart && date <= datePaidLeaveReliquatEnd,
    },
    'conge_reference': {
      advices: [
        t(`holidays.conge_reference.advices.0`, { year, yearBefore: year - 1 }),
      ],
      limit: date < new Date(year, 5, 30) ? new Date(year, 7, 31) : new Date(year + 1, 7, 31),
      consumed: parseFloat(holidays['conge_reference'].consumed) + parseFloat(holidays['conge_reference_anciennete'].consumed),
      total: parseFloat(holidays['conge_reference'].total) + parseFloat(holidays['conge_reference_anciennete'].total),
      visible: true,
    },
    'conge_en_cours': {
      advices: [
        t(`holidays.conge_en_cours.advices.0`, { year, yearAfter: year + 1 }),
        t(`holidays.conge_en_cours.advices.1`),
        t(`holidays.conge_en_cours.advices.2`, { yearEvenAfter: 2026, total: 25 }),
        t(`holidays.conge_en_cours.advices.3`, { accumulation: 2.08 }),
      ],
      limit: date < new Date(year, 5, 30) ? new Date(year + 1, 7, 31) : new Date(year + 2, 7, 31),
      consumed: parseFloat(holidays['conge_en_cours'].consumed) + parseFloat(holidays['conge_en_cours_anciennete'].consumed),
      total: parseFloat(holidays['conge_en_cours'].total) + parseFloat(holidays['conge_en_cours_anciennete'].total),
      visible: true,
    },
  }), [holidays])

  const getDateAsLocalizedParameters = useCallback((value) => ({
    day: value.getDate().toString().padStart(2, '0'),
    month: t(`months.${monthList[value.getMonth()]}.full`),
    monthIndex: (value.getMonth() + 1).toString().padStart(2, '0'),
    year: value.getFullYear().toString(),
  }), [])

  const navigate = useCallback((url) => { window.location.href = url + window.location.search }, [])
  const navigateInOtherTab = useCallback((url) => window.open(url, '_blank').focus(), [])

  return (
    <HolidaysStyled className="octo" illustration={illustration}>
      {
        isAdmin && <h1>{t('holidays.title')}</h1>
      }
      {
        isAdmin && (
        <div className="controls">
          <InputSearch
            className="search"
            name={t('holidays.search.arialabel')}
            title={t('holidays.search.text')}
            onSearch={async (search) => {
              if (!search || search.length < SEARCH_LENGTH_MIN) return null

              const response = await useFetch('GET', `/people?format=json&search=${search}`)
              const responseFormatted = response.map(({ nickname, firstname, lastname }) => ([nickname, `${nickname} - ${firstname} ${lastname}`]))

              return Object.fromEntries(responseFormatted)
            }}
            onChange={({ key }) => key && navigate(`/people/${key}/holidays`)}
          />
          <div className="today">
            <div className="today-name">{t('holidays.today')}</div>
            <div className="today-date">{t('holidays.date', getDateAsLocalizedParameters(DATE_TODAY))}</div>
          </div>
        </div>
        )
      }
      <header>
        <span className="title">
          <h2>{`${t('holidays.welcome', { firstname: person['first_name'], lastname: person['last_name'], nickname: person['nickname'] })}`}</h2>
          {
            isCurrentPerson
              ? (
                <Tooltip
                  className={getClass('notifications', person['is_mandatory_paid_leave_fulfilled'] || 'active')}
                  skin="light"
                  position="left"
                  trigger={({ open, close }) => <div className="bell" onMouseEnter={() => open()} onMouseLeave={() => close()} />}
                  content={() => (
                    <ReactMarkdown>
                      {
                        person['is_mandatory_paid_leave_fulfilled']
                          ? t('holidays.notifications.nothing')
                          : t('holidays.notifications.mandatory', getDateAsLocalizedParameters(counters['conge_reliquat'].limit))
                      }
                    </ReactMarkdown>
                  )}
                />
              )
              : (
                <>
                  <Button
                    skin="dark"
                    kind="secondary"
                    name={t('holidays.email.arialabel')}
                    text={t('holidays.email.text')}
                    icon={{ position: 'right', url: 'send' }}
                    onClick={() => navigateInOtherTab(`mailto:${person['email']}`)}
                  />
                  <Button
                    skin="light"
                    kind="primary"
                    name={t('holidays.cra.arialabel')}
                    text={t('holidays.cra.text')}
                    onClick={() => navigateInOtherTab(`/timesheet/${person['nickname']}`)}
                  />
                </>
              )
          }
        </span>
        {
          person['leaving_date'] && (
          <Tag
            className="leaving"
            color="red"
            text={t('holidays.infos.leaving_date', { date: t('holidays.date', getDateAsLocalizedParameters(dateLeaving)) })}
          />
          )
        }
        <div className="infos">
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.entry_date')} : `}</span>
            <span className="info-value">{t('holidays.date', getDateAsLocalizedParameters(dateEntry))}</span>
          </div>
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.work_schedule')} : `}</span>
            <span className="info-value">{person['work_schedule'].name }</span>
          </div>
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.league')} : `}</span>
            <span className="info-value">{ person['league'].name}</span>
          </div>
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.lob')} : `}</span>
            <span className="info-value">{person['lob'].name }</span>
          </div>
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.mandatory_main_paid_leave_fulfilled')} : `}</span>
            <span className="info-value">{person['is_mandatory_paid_leave_fulfilled'] ? <Tag text={t('yes')} color="green" /> : <Tag text={t('no')} color="red" />}</span>
          </div>
        </div>
        <div className="infos">
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.paid_leave_reliquats_postpone')} : `}</span>
            <span className="info-value">
              {
                person['paid_leave_reliquats_postpone']
                  ? <Tag text={t('holidays.date', getDateAsLocalizedParameters(datePaidLeaveReliquatPostpone))} color="purple" />
                  : <Tag text={t('no')} color="red" />
              }
            </span>
          </div>
          <div className="info">
            <span className="info-name">{`${t('holidays.infos.rtt_reliquats_postpone')} : `}</span>
            <span className="info-value">
              {
                person['rtt_reliquats_postpone']
                  ? <Tag text={t('holidays.date', getDateAsLocalizedParameters(dateRTTReliquatPostpone))} color="purple" />
                  : <Tag text={t('no')} color="red" />
              }
            </span>
          </div>
        </div>
      </header>
      <InputDate
        className="picker"
        skin="light"
        title={t('holidays.picker.title')}
        valueInitial={date}
        valueMinimum={DATE_TODAY}
        value={date || DATE_TODAY}
        precision="month"
        localization={{
          months,
          previous: t('holidays.picker.title'),
          next: t('holidays.picker.title'),
        }}
        onChange={(value) => { setWindowDate(value) }}
      />
      <div className="counters">
        {
          Object.entries(counters).map(([type, counter]) => counter.visible && (
            <HolidaysCounter
              title={t(`holidays.${type}.name`)}
              advices={counter.advices}
              alert={counter.alert}
              limit={counter.limit}
              consumed={counter.consumed}
              total={counter.total}
              isImportant={type === 'rtt'}
              key={type}
            />
          ))
        }
      </div>
      <div className="absences">
        {
          Object.entries(absences).map(([absencesYear, absencesGroupedByActivity]) => (
            <Dropdown
              className="absences-year"
              title={t('holidays.year', { year: absencesYear })}
              localization={{
                close: { arialabel: t('holidays.dropdown.close.arialabel'), text: t('holidays.dropdown.close.text') },
                open: { arialabel: t('holidays.dropdown.open.arialabel'), text: t('holidays.dropdown.open.text') },
              }}
              key={absencesYear}
            >
              {
                Object.entries(absencesGroupedByActivity).map(([id, absencesGroupedByConsecutiveTimeInputs]) => (
                  <div className="absences-activity" key={id}>
                    <div className="absences-activity-name">{activities[id]}</div>
                    <div className="absences-activity-list">
                      {
                        absencesGroupedByConsecutiveTimeInputs.map((absence) => (
                          <div className="absence" key={absence.start_date}>
                            <div className="absence-interval">
                              {
                                t('holidays.input.interval', {
                                  dateStart: t('holidays.input.day', getDateAsLocalizedParameters(new Date(absence['start_date']))),
                                  dateEnd: t('holidays.input.day', getDateAsLocalizedParameters(new Date(absence['end_date']))),
                                })
                              }
                            </div>
                            <div className="absence-duration">{t('holidays.input.duration', { count: parseFloat(absence['time_in_days']) })}</div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </Dropdown>
          ))
        }
      </div>
      <div className="tips">
        <div className="tips-input">
          <div className="tips-input-cra">{t('holidays.tips.input.cra')}</div>
          <div className="tips-input-docs">{t('holidays.tips.input.docs')}</div>
          <div className="tips-input-confluence">
            <Button className="confluence" skin="dark" kind="primary" text={t('holidays.tips.input.confluence')} onClick={() => navigateInOtherTab(URL_CONFLUENCE)} />
          </div>
          <div className="tips-input-contact">
            <span>{t('holidays.tips.input.contact')}</span>
            <a target="_blank" rel="noreferrer" href={`mailto:${URL_SUPPORT_PAIE}`}>{URL_SUPPORT_PAIE}</a>
            <span>{ ` ${t('or')} `}</span>
            <a target="_blank" rel="noreferrer" href={`mailto:${URL_SUPPORT_ADMINRH}`}>{URL_SUPPORT_ADMINRH}</a>
          </div>
        </div>
        <div className="tips-reducing">
          <div className="tips-reducing-header">{t('holidays.tips.reducing.header')}</div>
          <ul className="tips-reducing-list">
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.sick')}</span>
                <span>{t('holidays.tips.reducing.rtt')}</span>
              </div>
            </li>
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.unpaid')}</span>
                <span>{t('holidays.tips.reducing.all')}</span>
              </div>
            </li>
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.maternity')}</span>
                <span>{t('holidays.tips.reducing.rtt')}</span>
              </div>
            </li>
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.parental')}</span>
                <span>{t('holidays.tips.reducing.all')}</span>
              </div>
            </li>
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.unjustified')}</span>
                <span>{t('holidays.tips.reducing.all')}</span>
              </div>
            </li>
            <li>
              <div className="tips-reducing-list-item">
                <span>{t('holidays.tips.reducing.list.personal')}</span>
                <span>{t('holidays.tips.reducing.all')}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </HolidaysStyled>
  )
}
