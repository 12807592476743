import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import { clean, getClass, convertSnakecaseToCamelcase } from '../../helpers/stringHelper'
import DatePicker from '../input/_ExportDS/DatePicker/DatePicker'
import Select from '../input/_ExportDS/Select/Select'
import Camera from '../input/_ExportDS/Camera/Camera'
import Loader from '../input/_ExportDS/Loader/Loader'
import SelectAsync from '../input/_ExportDS/SelectAsync/SelectAsync'
import SelectEnum from '../input/_ExportDS/SelectEnum/SelectEnum'
import Button from '../input/_ExportDS/Button/Button'
import TextInput from '../input/_ExportDS/Input/Input'
import Textarea from '../input/_ExportDS/Textarea/Textarea'
import { Expense } from '../../structures/Expense/Expense'
import ExpensesFormStyled from './ExpensesForm.styled'
import pictoLeft from '../../../assets/images/left.svg'
import ExpensesFormReceipt from '../ExpensesFormReceipt/ExpensesFormReceipt'
import { ExpensesFormAttendeesPeople } from '../ExpensesFormAttendeesPeople/ExpensesFormAttendeesPeople'

const today = new Date()
const todayNormalized = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)

const DATEPICKER_YEAR_MIN = 2013
const DATEPICKER_YEAR_MAX = todayNormalized.getFullYear() + 2
const SELECT_MAX_OPTIONS = 10
const TELEWORK_SETUP_PROJECT = 2146913199
const TELEWORK_SETUP_WBS = 25
const TELEWORK_SETUP_SPAN_YEARS = 5

export default function ExpensesForm() {
  const { t } = useTranslation()
  const {
    dateOfLastTeleworkSetupExpense,
    initialType,
    options,
    expenseSelected,
    setViewToList,
    hasErrorInReceiptFile,
    fetchCreateExpense,
    fetchUpdateExpense,
    isLoadingValidationForm,
    setGroupAttendees,
  } = useExpensesContext()

  const [expense, setExpense] = useState(null)
  const [hasClickedOnceOnValidate, setHasClickedOnceOnValidate] = useState(false)

  const parseErrors = () => {
    const type = expense.getValue('type')
    const day = expense.getValue('day')
    const value = expense.getValue('value')
    const attendeesNumber = expense.getValue('attendees_number')
    const kilometers = expense.getValue('kilometers')
    const attendeesPeopleCount = expense.getValue('people_attendee_ids').length
    const mealKind = expense.getValue('meal_kind')
    const isLastTeleworkSetupExpenseLessThan5YearsAgo = day === null || new Date(dateOfLastTeleworkSetupExpense) >= new Date(
      day.getFullYear() - TELEWORK_SETUP_SPAN_YEARS,
      day.getMonth(),
      day.getDate(),
    )

    return {
      datesAreIncoherent:
        expense.getVisibility('day_end') && day > expense.getValue('day_end')
          ? t('expenses.errors.datesCoherence') : null,
      dateOfLastTeleworkSetupExpenseIsLessThan5YearsAgo:
        expense.getVisibility('day') && type === 'TeleworkSetupExpense'
          && isLastTeleworkSetupExpenseLessThan5YearsAgo && initialType !== 'TeleworkSetupExpense'
          ? t('expenses.errors.dateOfLastTeleworkSetupExpenseIsLessThan5YearsAgo') : null,
      valueIsNotPositive:
        expense.getVisibility('value') && value && value < 0
          ? t('expenses.errors.numberStrictlyPositive') : null,
      valueOfTeleworkSetupExpenseIsNotGreaterThanTwo:
        expense.getVisibility('value') && value && value < 2 && type === 'TeleworkSetupExpense'
          ? t('expenses.errors.valueOfTeleworkSetupExpenseIsNotGreaterThanTwo') : null,
      attendeesNumberIsNotStrictlyPositive:
        expense.getVisibility('attendees_number') && attendeesNumber && attendeesNumber < 0
          ? t('expenses.errors.numberStrictlyPositiveInteger') : null,
      attendeesNumberIsLessThanAttendeesPeopleCount:
        expense.getVisibility('attendees_number') && expense.getVisibility('people_attendee_ids') && attendeesNumber < attendeesPeopleCount
          ? t('expenses.errors.attendeesNumberMustBeEqualOrGreaterToAttendeesPeopleCount') : null,
      kilometersIsNotStrictlyPositive:
        expense.getVisibility('kilometers') && kilometers && kilometers < 0
          ? t('expenses.errors.numberStrictlyPositive') : null,
      attendeesPeopleIsNotStrictlyPositive:
      expense.getVisibility('attendees_number') && expense.getVisibility('people_attendee_ids') && attendeesNumber > 0 && attendeesPeopleCount < 1 && mealKind === 'lunch'
        ? t('expenses.errors.attendeesPeopleIsNotStrictlyPositive') : null,
    }
  }

  const parseErrorsIncludingRequirements = () => ({
    ...parseErrors(),
    ...Object.fromEntries(expense.attributes.map(({ key }) => [
      `${convertSnakecaseToCamelcase(key)}IsRequired`,
      key !== 'id' && key !== 'status' && key !== 'to_send' && expense.getVisibility(key) && !expense.getValue(key)
        ? t('expenses.errors.isRequired')
        : null,
    ])),
  })

  const setExpenseFromCopy = (expenseCopy) => {
    const expenseToUpdate = new Expense(expenseCopy.toObject())
    const type = expenseToUpdate.getValue('type')
    const isInternal = options.projects.find((project) => project.name === expenseToUpdate.getValue('project_id'))?.isInternal
    const isMeal = ['CustomerMealExpense', 'TeamMealExpense'].includes(type)
    const isTravel = ['KmAllowanceExpense', 'TrainExpense', 'PlaneExpense'].includes(type)

    expenseToUpdate.setVisibility('type', true)
    expenseToUpdate.setVisibility('day', true)
    expenseToUpdate.setVisibility('day_end', isTravel || ['HotelExpense', 'CarExpense'].includes(type))
    expenseToUpdate.setVisibility('project_id', true)
    expenseToUpdate.setVisibility('wbs_category_id', isInternal)
    expenseToUpdate.setVisibility('value', true)
    expenseToUpdate.setVisibility('refund_value', type === 'TeleworkSetupExpense')
    expenseToUpdate.setVisibility('country_id', true)
    expenseToUpdate.setVisibility('invoicing_kind', true)
    expenseToUpdate.setVisibility('meal_kind', isMeal)
    expenseToUpdate.setVisibility('attendees_number', isMeal)
    expenseToUpdate.setVisibility('departure', isTravel)
    expenseToUpdate.setVisibility('arrival', isTravel)
    expenseToUpdate.setVisibility('kilometers', type === 'KmAllowanceExpense')
    expenseToUpdate.setVisibility('reason', type === 'CarExpense')
    expenseToUpdate.setVisibility('supplier', ['HotelExpense', 'PlaneExpense'].includes(type))
    expenseToUpdate.setVisibility('travel_class', type === 'PlaneExpense')
    expenseToUpdate.setVisibility('vat', type !== 'KmAllowanceExpense')
    expenseToUpdate.setVisibility('label', true)
    expenseToUpdate.setVisibility('receipts', type !== 'KmAllowanceExpense')

    setExpense(expenseToUpdate)
  }

  const updateExpense = () => setExpenseFromCopy(expense)

  const updateExpenseToContext = () => setExpenseFromCopy(expenseSelected)

  const updateExpenseAttribute = (key, value) => {
    expense.setValue(key, value)

    const type = expense.getValue('type')
    if (type === 'TeleworkSetupExpense') {
      expense.setValue('refund_value', expense.getRefund())
    } else if (type === 'KmAllowanceExpense') {
      expense.setValue('value', expense.getValueFromKilometers())
    }

    updateExpense()

    parseErrors()
  }

  const updateExpenseType = (value) => {
    expense.setValue('supplier', null)

    if (value === 'TeleworkSetupExpense') {
      expense.setValue('project_id', TELEWORK_SETUP_PROJECT)
      expense.setValue('value', null)
      expense.setValue('refund_value', null)
      expense.setValue('wbs_category_id', TELEWORK_SETUP_WBS)
      expense.setValue('invoicing_kind', options.invoicingKinds.at(-1).name)
      expense.setValue('vat', 'no')
    } else {
      if (expense.getValue('project_id') === TELEWORK_SETUP_PROJECT) {
        expense.setValue('refund_value', null)
        expense.setValue('invoicing_kind', null)
        expense.setValue('project_id', null)
      }
      if (value === 'KmAllowanceExpense') { expense.setValue('kilometers', null) }
      expense.setValue('wbs_category_id', null)
    }

    if (['CustomerMealExpense', 'TeamMealExpense'].includes(value)) {
      expense.setValue('meal_kind', 'lunch')
    } else {
      expense.setValue('meal_kind', null)
    }

    updateExpenseAttribute('type', value)
  }

  const getControlClasses = (name) => getClass(
    'control',
    expense.getVisibility(name) ? '' : 'hidden',
    expense.getValue(name) ? 'filled' : '',
  )

  const addReceipt = (receipt) => {
    updateExpenseAttribute('receipts', [...expense.getValue('receipts'), {
      id: null,
      url: URL.createObjectURL(receipt),
      file: receipt,
    }])
  }

  const destroyReceipt = (receiptIndex) => {
    const receipts = expense.getValue('receipts')
    receipts.splice(receiptIndex, 1)

    updateExpenseAttribute('receipts', receipts)
  }

  const validateExpense = async () => {
    const errors = parseErrorsIncludingRequirements()
    if (Object.values(errors).filter(Boolean).length === 0) {
      return expense.getValue('id') === null ? fetchCreateExpense(expense.toObject()) : fetchUpdateExpense(expense.toObject())
    }
    return errors
  }

  useEffect(() => {
    if (expenseSelected !== null && options.types.length > 0) {
      if (expenseSelected.getValue('type') === 'TeleworkSetupExpense') {
        expenseSelected.setValue('value', null)
      }

      if (!expenseSelected.getValue('day')) {
        expenseSelected.setValue('day', todayNormalized)
      }

      if (!expenseSelected.getValue('day_end')) {
        expenseSelected.setValue('day_end', todayNormalized)
      }

      if (!expenseSelected.getValue('country_id')) {
        expenseSelected.setValue('country_id', options.countries.find((country) => country.slug === 'eur france')?.name)
      }

      setHasClickedOnceOnValidate(false)
      updateExpenseToContext()
    }
  },
  [expenseSelected])

  return (
    <ExpensesFormStyled>
      <div className="header">
        <button
          className="previous"
          aria-label="expenses return to list"
          type="button"
          style={{ backgroundImage: `url(${pictoLeft})` }}
          onClick={() => {
            setViewToList()
            setGroupAttendees([])
          }}
        />
        <span className="title">{t('expenses.add')}</span>
      </div>
      <div className="description">
        <span className="warning">{`${t('expenses.warning')} `}</span>
        <span>{t('expenses.warningPhoto')}</span>
        <div><span>{t('expenses.mandatoryTooltip')}</span></div>
      </div>
      <div className="form">
        {
          expense && (() => {
            const typeSelected = expense.getValue('type')
            const receipts = expense.getValue('receipts')
            const errors = hasClickedOnceOnValidate ? parseErrorsIncludingRequirements() : parseErrors()
            const errorInReceipt = hasErrorInReceiptFile.current ? t('expenses.errors.receiptShouldBeAnImageOrSinglePagePDF') : null
            const errorInForm = Object.values(errors).filter(Boolean).length > 0 && t('expenses.errors.validation')
            const errorInValidation = errorInReceipt || errorInForm || null

            if (errorInReceipt !== null) {
              hasErrorInReceiptFile.current = false
            }

            return (
              <>
                <Select
                  className={getControlClasses('type')}
                  name="type"
                  title={t('expenses.controls.type')}
                  text={options.types.find((type) => type.name === typeSelected)?.title}
                  options={options.types}
                  onChange={(option) => {
                    if (expense.getValue('type') === 'KmAllowanceExpense') {
                      expense.setValue('kilometers', null)
                      expense.setValue('value', null)
                    }
                    updateExpenseType(option.name)
                  }}
                />
                {
                  typeSelected && (
                    <>
                      <DatePicker
                        className={getControlClasses('day')}
                        name="day"
                        title={['HotelExpense', 'KmAllowanceExpense', 'CarExpense', 'TrainExpense', 'PlaneExpense']
                          .includes(typeSelected)
                          ? t('expenses.controls.day')
                          : t('expenses.controls.day_unique')}
                        value={expense.getValue('day')}
                        error={errors.dayIsRequired || errors.dateOfLastTeleworkSetupExpenseIsLessThan5YearsAgo}
                        yearStart={DATEPICKER_YEAR_MIN}
                        yearEnd={DATEPICKER_YEAR_MAX}
                        detail="month"
                        onChange={(date) => updateExpenseAttribute('day', date)}
                      />
                      <DatePicker
                        className={getControlClasses('day_end')}
                        name="dayEnd"
                        title={t('expenses.controls.day_end')}
                        value={expense.getValue('day_end')}
                        error={errors.dayEndIsRequired || errors.datesAreIncoherent}
                        yearStart={DATEPICKER_YEAR_MIN}
                        yearEnd={DATEPICKER_YEAR_MAX}
                        detail="month"
                        onChange={(date) => updateExpenseAttribute('day_end', date)}
                      />
                      <SelectAsync
                        className={getControlClasses('project_id')}
                        name="project_id"
                        title={t('expenses.controls.project_id')}
                        text={options.projects.find((project) => project.name === expense.getValue('project_id'))?.title}
                        placeholder={t('expenses.placeholder.search')}
                        disabled={typeSelected === 'TeleworkSetupExpense'}
                        error={errors.projectIdIsRequired || errors.projectIsRequired}
                        onSearch={async (search) => {
                          const searchCleaned = clean(search)
                          return options.projects.filter((project) => project.slug.includes(searchCleaned))
                        }}
                        onResponse={(response) => response}
                        onChange={(option) => {
                          updateExpenseAttribute('project_id', option.name)
                        }}
                      />
                      <Select
                        className={getControlClasses('wbs_category_id')}
                        name="wbs_category_id"
                        title={t('expenses.controls.wbs_category_id')}
                        text={options.wbsCategories.find((wbs) => wbs.name === expense.getValue('wbs_category_id'))?.title}
                        disabled={typeSelected === 'TeleworkSetupExpense'}
                        options={options.wbsCategories}
                        error={errors.wbsCategoryIdIsRequired || errors.wbsIsRequired}
                        onChange={(option) => updateExpenseAttribute('wbs_category_id', parseInt(option.name, 10))}
                      />
                      <TextInput
                        className={getControlClasses('kilometers')}
                        name="kilometers"
                        title={t('expenses.controls.kilometers')}
                        type="number"
                        value={expense.getValue('kilometers')}
                        error={errors.kilometersIsRequired || errors.kilometersIsNotStrictlyPositive}
                        onChange={(value) => updateExpenseAttribute('kilometers', value)}
                      />
                      <TextInput
                        className={getControlClasses('value')}
                        name="value"
                        title={t('expenses.controls.value')}
                        type="number"
                        value={expense.getValue('value')}
                        error={errors.valueIsRequired || errors.valueIsNotPositive || errors.valueOfTeleworkSetupExpenseIsNotGreaterThanTwo}
                        disabled={typeSelected === 'KmAllowanceExpense'}
                        onChange={(value) => updateExpenseAttribute('value', value)}
                      />
                      <Button
                        className={getControlClasses('refund_value')}
                        name="refund_value"
                        title={t('expenses.controls.refund_value')}
                        text={expense.getValue('refund_value')}
                        disabled
                      />
                      <SelectAsync
                        className={getControlClasses('country_id')}
                        name="country_id"
                        title={t('expenses.controls.country_id')}
                        text={options.countries.find((country) => country.name === expense.getValue('country_id'))?.title}
                        placeholder={t('expenses.placeholder.search')}
                        error={errors.countryIdIsRequired}
                        onSearch={async (search) => {
                          const searchCleaned = clean(search)
                          return options.countries.filter((country) => country.slug.includes(searchCleaned))
                        }}
                        onResponse={(response) => response.slice(0, SELECT_MAX_OPTIONS)}
                        onChange={(option) => updateExpenseAttribute('country_id', option.name)}
                      />
                      <Select
                        className={getControlClasses('invoicing_kind')}
                        name="invoicing_kind"
                        title={t('expenses.controls.invoicing_kind')}
                        text={options.invoicingKinds.find((invoicing) => invoicing.name === expense.getValue('invoicing_kind'))?.title}
                        disabled={typeSelected === 'TeleworkSetupExpense'}
                        options={options.invoicingKinds}
                        error={errors.invoicingKindIsRequired}
                        onChange={(option) => updateExpenseAttribute('invoicing_kind', option.name)}
                      />
                      <TextInput
                        className={getControlClasses('attendees_number')}
                        name="attendees_number"
                        title={t('expenses.controls.attendees_number')}
                        valueInitial={expense.getValue('attendees_number')}
                        value={expense.getValue('attendees_number') < expense.getValue('people_attendee_ids').length
                          ? updateExpenseAttribute('attendees_number', parseFloat(expense.getValue('people_attendee_ids').length, 10)) : expense.getValue('attendees_number')}
                        error={errors.attendeesNumberIsRequired || errors.attendeesNumberIsNotStrictlyPositive}
                        onChange={(value) => updateExpenseAttribute('attendees_number', parseFloat(value, 10))}
                      />
                      {
                        expense.getValue('meal_kind') === 'lunch'
                      && (
                      <div>
                        <ExpensesFormAttendeesPeople
                          className={getControlClasses('people_attendee_ids')}
                          updateExpenseAttribute={updateExpenseAttribute}
                          error={errors.peopleAttendeeIdsIsRequired || errors.attendeesPeopleIsNotStrictlyPositive}
                        />
                      </div>
                      )
                      }
                      <SelectEnum
                        className={getControlClasses('meal_kind')}
                        name="meal_kind"
                        title={t('expenses.controls.meal_kind')}
                        value={expense.getValue('meal_kind')}
                        items={options.mealKinds}
                        error={errors.mealKindIsRequired || errors.mealKindIsNotStrictlyPositive}
                        onChange={(option) => updateExpenseAttribute('meal_kind', option.name)}
                      />
                      <TextInput
                        className={getControlClasses('departure')}
                        name="departure"
                        title={t('expenses.controls.departure')}
                        value={expense.getValue('departure')}
                        valueInitial={expense.getValue('departure')}
                        error={errors.departureIsRequired}
                        onChange={(value) => updateExpenseAttribute('departure', value)}
                      />
                      <TextInput
                        className={getControlClasses('arrival')}
                        name="arrival"
                        title={t('expenses.controls.arrival')}
                        value={expense.getValue('arrival')}
                        valueInitial={expense.getValue('arrival')}
                        error={errors.arrivalIsRequired}
                        onChange={(value) => updateExpenseAttribute('arrival', value)}
                      />
                      <Select
                        className={getControlClasses('reason')}
                        name="reason"
                        title={t('expenses.controls.reason')}
                        text={options.reasons.find((reason) => reason.name === expense.getValue('reason'))?.title}
                        options={options.reasons}
                        error={errors.reasonIsRequired}
                        onChange={(option) => updateExpenseAttribute('reason', option.name)}
                      />
                      <Select
                        className={getControlClasses('supplier')}
                        name="supplier"
                        title={t('expenses.controls.supplier')}
                        text={typeSelected === 'HotelExpense'
                          ? options.suppliers.hotel.find((supplier) => supplier.name === expense.getValue('supplier'))?.title
                          : options.suppliers.plane.find((supplier) => supplier.name === expense.getValue('supplier'))?.title}
                        options={typeSelected === 'HotelExpense' ? options.suppliers.hotel : options.suppliers.plane}
                        error={errors.supplierIsRequired}
                        onChange={(option) => updateExpenseAttribute('supplier', option.name)}
                      />
                      <Select
                        className={getControlClasses('travel_class')}
                        name="travel_class"
                        title={t('expenses.controls.travel_class')}
                        text={options.travelClasses.find((classItem) => classItem.name === expense.getValue('travel_class'))?.title}
                        options={options.travelClasses}
                        error={errors.travelClassIsRequired}
                        onChange={(option) => updateExpenseAttribute('travel_class', option.name)}
                      />
                      <SelectEnum
                        className={getControlClasses('vat')}
                        name="vat"
                        title={t('expenses.controls.vat')}
                        value={expense.getValue('vat')}
                        disabled={typeSelected === 'TeleworkSetupExpense'}
                        items={options.vat}
                        error={errors.vatIsRequired}
                        onChange={(option) => updateExpenseAttribute('vat', option.name)}
                      />
                      <Textarea
                        className={getControlClasses('label')}
                        name="label"
                        title={t('expenses.controls.label')}
                        value={expense.getValue('label')}
                        error={errors.labelIsRequired}
                        onChange={(value) => updateExpenseAttribute('label', value)}
                        maxLength={255}
                      />
                      <div className={getControlClasses('receipts')}>
                        <div className="receipt-title">{t('expenses.controls.receipts')}</div>
                        {
                          receipts[0] && <ExpensesFormReceipt className="receipt" receipt={receipts[0]} onDestroy={() => destroyReceipt(0)} />
                        }
                        {
                          receipts[1] && <ExpensesFormReceipt className="receipt" receipt={receipts[1]} onDestroy={() => destroyReceipt(1)} />
                        }
                        <Camera
                          className="receipt-uploader"
                          text={t('expenses.controls.receiptsAdd')}
                          disabled={expense.getValue('receipts').length >= 2}
                          onChange={(value) => addReceipt(value)}
                        />
                      </div>
                      <div className="form-validation">
                        {
                            isLoadingValidationForm
                              ? <Loader />
                              : (
                                <Button
                                  className="form-validation-trigger"
                                  name="validate expense"
                                  text={t('expenses.validate')}
                                  disabled={errorInValidation !== null}
                                  error={errorInValidation}
                                  onClick={() => {
                                    setHasClickedOnceOnValidate(true)
                                    validateExpense()
                                  }}
                                />
                              )
                          }
                      </div>
                    </>
                  )
                }
              </>
            )
          })()
        }
      </div>
    </ExpensesFormStyled>
  )
}
